import React, { Component } from "react";
import {
  Navigate,
  useNavigate,
  Route,
  Routes,
} from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import Dashboard from "./Admin/views/Dashboard";
import AboutUs from "./pages/About";
import ContactUs from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./Admin/Login"
import Images from "./Admin/views/Images";
import AboutTrust from "./Admin/views/AboutTrust";
import Inquiry from "./Admin/views/Inquiry";
import ExamInquiry from "./Admin/views/ExamInquiry";
import AboutTrustee from "./pages/AboutTrustee";
import GetInquiry from "./pages/GetInquiry";
import FRC from "./pages/FRC";
import Download from "./pages/Download";
import OurTeam from "./pages/OurTeam";
import Gallery from "./pages/Gallery";
import News from "./pages/News";

const Admin = React.lazy(() => import('./layouts/Admin')); // Lazy-loaded
const Main = React.lazy(() => import('./layouts/Main')); // Lazy-loaded

const loading = (
  <div className="book_preload">
    <div className="book">
      <div className="book__page"></div>
      <div className="book__page"></div>
      <div className="book__page"></div>
    </div>
  </div>
)

function App() {

  return (
    <React.Fragment>
      <React.Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<Main {...loading} />}>
            <Route index element={<Home />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="gallery" element={<Gallery {...loading} />} />
            <Route path="news" element={<News {...loading} />} />
            <Route path="frc" element={<FRC />} />
            <Route path="downloads" element={<Download />} />
            <Route path="ourTeam" element={<OurTeam />} />
            <Route path="getInquiry" element={<GetInquiry />} />
            <Route path="aboutTrustee" element={<AboutTrustee />} />
            <Route path="*" element={<Main />} />
          </Route>
          <Route path="login" element={<Login {...loading} />} />
          <Route path="admin" element={<Navigate to="/login" />} />
          <Route element={<Admin />}>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard {...loading} />
                </RequireAuth>
              }
            />
            <Route
              path="/images"
              element={
                <RequireAuth>
                  <Images {...loading} />
                </RequireAuth>
              }
            />
            <Route
              path="/aboutTrust"
              element={
                <RequireAuth>
                  <AboutTrust {...loading} />
                </RequireAuth>
              }
            />
            <Route
              path="/inquiry"
              element={
                <RequireAuth>
                  <Inquiry {...loading} />
                </RequireAuth>
              }
            />
            <Route
              path="/examInquiry"
              element={
                <RequireAuth>
                  <ExamInquiry {...loading} />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </React.Suspense>
    </React.Fragment>
  );
}

function RequireAuth({ children }) {
  let authToken = sessionStorage.getItem('Auth Token')
  return authToken ? children : <Navigate to="/login" replace />;
}

export default App;
