import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doc, getDocs, deleteDoc, orderBy, collection } from "firebase/firestore";
import { db } from '../../firebase-config';

function Inquiry() {
  const [allInquiryData, setallInquiryData] = useState([]);

  const getInquiryData = async () => {
    const inquirySnapshot = await getDocs(collection(db, "contact"), orderBy("id", "desc"));
    const inquiryData = inquirySnapshot.docs.map((doc) => doc.data());
    if (inquiryData.length > 0) inquiryData.sort().reverse()
    setallInquiryData(inquiryData);
  }

  const deleteInquiryData = async (row, i) => {
    const inquiryData = doc(db, "contact", row.id);
    await deleteDoc(inquiryData).then((res) => {
      setallInquiryData(allInquiryData.filter(function (value, index, arr) { return value.id != row.id }))
      toast.success("Deleted Successfully")
    }).catch((err) => {
      console.log(err)
    });
  };

  useEffect(() => {
    getInquiryData()
  }, [])

  
  return (
    <>
      <ToastContainer />
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Inquiry</h4>
            <div class="page-title-right">
              <nav class="" aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                  <li className="breadcrumb-item active">Inquiry</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              {/* <div class="row">
                <div class="col-sm-4">
                  <h5 className="form-title"><span>Inquiry</span></h5>
                </div>
              </div> */}
              <div class="table-responsive mt-3">
                <table class="mb-0 table table-bordered text-center align-items-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Subject</th>
                      <th>Message</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allInquiryData.length > 0 ?
                      allInquiryData.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.fname}</td>
                            <td>{item.lname}</td>
                            <td>{item.email}</td>
                            <td>{item.subject}</td>
                            <td>{item.message}</td>
                            <td key="actions" style={{ width: '220px' }}>
                              <button type="button" class="btn btn-outline-danger" onClick={(e) => deleteInquiryData(item, i)}>
                                <i className="fa fa-trash pe-2"></i>
                                Delete
                              </button>
                            </td>
                          </tr>
                        )

                      }) : (
                        <tr>
                          <td colSpan="4">No Record Found</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default Inquiry;
