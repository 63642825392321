import React from "react";
import { NavLink } from "react-router-dom";
import routes from "../routes";

function Navbar() {
  return (
    <React.Fragment>
      {/* <!-- Menu Start --> */}
      <div class="menu-area menu-sticky py-2">
        <div class="container relative">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
              <div class="logo-area">
                <a href="/">
                  <img src="assets/images/logo.jpg" alt="logo" width={200} />
                </a>
              </div>
            </div>
            <div class="col-lg-9 col-md-12">
              <div class="main-menu">
                <a class="rs-menu-toggle">
                  <i class="fa fa-bars" aria-hidden="true"></i>Menu
                </a>
                <nav class="rs-menu">
                  <ul className="nav-menu">
                    {routes.routes.map((prop, key) => (
                      <li>
                        <NavLink
                          className="nav-link kpcmenu"
                          activeclassname="active"
                          to={prop.path}
                        >
                          {prop.name}
                        </NavLink>
                      </li>
                    ))}
                    <li>
                      <a href="/getInquiry" class="btn btn-sm apply-btn">
                        Apply Now
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <div class="apply-box">
                <a href="/getInquiry" class="apply-btn">
                  Apply Now
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Navbar;
