import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { app } from '../firebase-config';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const { handleSubmit, register, errors } = useForm();
  let navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, data.email, data.password)
      .then((response) => {
        navigate('/dashboard')
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
        sessionStorage.setItem('User Id', response.user.uid)
      }).catch((error) => {
        if (error.code === 'auth/wrong-password') {
          toast.error('Please check the Password');
        }
        if (error.code === 'auth/user-not-found') {
          toast.error('Please check the Email');
        }
      })

  });

  // Styles
  return (
    <React.Fragment>
      <ToastContainer />
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-sm-center h-100">
            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
              <div className="text-center my-2">
                <img src="assets/images/fev.jpg" alt="logo" width="150" />
              </div>
              <div className="card shadow-lg">
                <div className="card-body p-5">
                  <h1 className="fs-4 card-title fw-bold mb-4 text-center">KPC ADMIN LOGIN</h1>
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="email">E-Mail Address</label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        name="email"
                        {...register("email")}
                        required
                        autoFocus />
                    </div>

                    <div className="mb-3">
                      <div className="mb-2 w-100">
                        <label className="text-muted" htmlFor="password">Password</label>
                      </div>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        {...register("password")}
                        name="password"
                        required />
                    </div>

                    <div className="d-flex align-items-center">
                      {/* <div className="form-check">
                        <input type="checkbox" name="remember" id="remember" className="form-check-input" />
                        <label htmlFor="remember" className="form-check-label">Remember Me</label>
                      </div> */}
                      <button type="submit" className="btn btn-primary ms-auto">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="text-center mt-5 text-muted">
                Copyright &copy; 2022 &mdash; <strong>
                  Elite Scriptech
                </strong>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default Login;


