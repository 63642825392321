import React from "react";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Staff(props) {
  const { sliderData } = props;
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <React.Fragment>
      {/* <!-- Team Start --> */}
      <div id="rs-team" className="rs-team sec-color py-5">
        <div className="container">
          <div className="row rs-vertical-middle">
            <div className="col-lg-4 col-md-12">
              <div className="sec-title mb-30">
                <h2>
                  <span class="orange-color">Our </span>Management Team
                </h2>
                <p>Actively engage students in their own learning.</p>
              </div>
              <p className="mobile-mb-50">
                KPC recognises that experienced and qualified staff add value to
                build a long term relationship with an organisation and supports
                them in enhancing and developing their careers, and Set a high
                expectation of what children can achieve to motivate children to
                achieve their educational and personal goal to their fullest
                potential.
              </p>
            </div>
            <div className="col-lg-8 col-md-12">
              {sliderData.length > 0 ? (
                <OwlCarousel
                  className="rs-carousel"
                  responsiveClass={true}
                  responsive={state.responsive}
                  items={2}
                  margin={30}
                  autoplay={false}
                  autoplayTimeout={3000}
                  nav={true}
                  navSpeed={false}
                  navText={[
                    "<i class='fa fa-angle-left'></i>",
                    "<i class='fa fa-angle-right'></i>",
                  ]}
                  navElement="div"
                >
                  {sliderData.map((element, key) => {
                    return (
                      <div class="team-item">
                        <div class="team-img">
                          <img
                            src={element.imageURL}
                            alt={element.imageTitle}
                          />
                          <div class="normal-text">
                            <h3 className="team-name">{element.imageTitle}</h3>
                            <span className="subtitle">{element.role}</span>
                          </div>
                        </div>
                        <div class="team-content">
                          <div class="overly-border"></div>
                          <div class="display-table">
                            <div class="display-table-cell">
                              <h3 class="team-name">
                                <a href="#">{element.imageTitle}</a>
                              </h3>
                              <span class="team-title">{element.role}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                <Skeleton style={{ height: "260px" }} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}
    </React.Fragment>
  );
}

export default Staff;
