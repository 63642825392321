import React from "react";

function Service() {
    return (
        <React.Fragment>
            {/* <!-- Services Start --> */}
            <div id="rs-learning-objectives" class="rs-learning-objectives pt-50 pb-70">
                <div class="container">
                    <div className="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="courses-item">
                                <i className="fa fa-desktop rs-animation-scale-up"></i>
                                <h4 className="courses-title">Computer Lab</h4>
                                <p>Computer in Kpc Botad is fun as we impart education,
                                    through interactive techniques using computer CDs,
                                    LCD Projectors and Multimedia.</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="courses-item">
                                <i className="fa fa-book rs-animation-scale-up"></i>
                                <h4 className="courses-title">Books & Liberary</h4>
                                <p>Kpc botad has 450 partially isolated self study tables in which 11-12(Sc.).
                                    students do their study during self study hours since this is a day school.</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="courses-item">
                                <i className="fa fa-soccer-ball-o rs-animation-scale-up"></i>
                                <h4 className="courses-title">Play Ground </h4>
                                <p>Kpc botad provides extensive training and coaching in various sports like
                                    Athletics , Basketball , Cricket , Football , Kabaddi ,Kho-Kho,Volleyball,Table.</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="courses-item">
                                <i className="fa fa-fire-extinguisher rs-animation-scale-up"></i>
                                <h4 className="courses-title">Safety & Security</h4>
                                <p>For the safety of the students the CCTV Camera in every classroom for centralize control,
                                    fire safety equipment and well trained Security Staff 24x7 .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Services End --> */}
        </React.Fragment>
    );
}

export default Service;
