import React, { useState, useEffect } from "react";

function AboutUs() {
  useEffect(() => {
   window.scrollTo(0,0)
  }, []);
  return (
    <React.Fragment>
      <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
        <div class="breadcrumbs-inner">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <h1 class="page-title my-2">About KPC</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- History Start --> */}
      <div class="rs-history sec-spacer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 rs-vertical-bottom mobile-mb-50">
              <img src="assets/images/photos/10.jpeg" alt="History Image" className="img-responsive img-thumbnail" />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">Our </span> History
                </h2>
              </div>
              <div class="about-desc">
                <p>
                  In June, 2007 KPC Botad had been established with the ideal
                  thought that if the youth of the nation approaches towards the
                  science, many problems can be solved easily.{" "}
                </p>
                <p>
                  The KPC school gives the experience of our ancient Ashram
                  education system to the students. Being aloof from all the
                  entertainment, Festivals and other extra activities, the
                  students get the highest & best results being engaged in
                  thorough educational programmers during the two years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- History End --> */}

      {/* <!-- Mission Start --> */}
      <div class="rs-mission sec-color sec-spacer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 mobile-mb-50">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">Our </span> Mission
                </h2>
              </div>
              <div class="about-desc">
                <p>
                  To fulfill the vision, KPC Botad provides an environment in
                  which every student discovers and realizes.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row">
                <div class="mobile-mb-30">
                  <img src="assets/images/mission.png" alt="Mission Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mission End --> */}

      {/* <!-- Vision Start --> */}
      <div class="rs-vision sec-spacer">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 mobile-mb-50">
              <div class="vision-img">
                <img src="assets/images/vision.png" alt="img02" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">Our </span> Vision
                </h2>
              </div>
              <div class="vision-desc">
                <p>
                  The KPC Botad aims to be an institution of excellence,
                  dedicated to produce leaders and good human being of future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Vision End --> */}
    </React.Fragment>
  );
}

export default AboutUs;
