import React from "react";

function Footer() {
  return (
    <React.Fragment>
      {/* <!-- Footer Start --> */}
      <footer id="rs-footer" className="bg3 rs-footer">
        {/* <!-- Footer Address --> */}
        <div className="footer-address">
          <div className="container">
            <div className="row footer-contact-desc">
              <div className="col-md-4">
                <div className="contact-inner">
                  <i className="fa fa-map-marker"></i>
                  <h4 className="contact-title">Address</h4>
                  <p className="contact-desc">
                    Shree Kadva Patidar Educational Campus <br />
                    B/H.Swami Vivekanand nagar seva sadan, <br />
                    Station Road, Botad,364710
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-inner">
                  <i className="fa fa-phone"></i>
                  <h4 className="contact-title">Phone Number</h4>
                  <p className="contact-desc">
                    +91 87804 71515 <br />
                    +91 84014 48283 <br />
                    (02849) 242476
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-inner">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <h4 className="contact-title">Email Address</h4>
                  <p className="contact-desc">
                    rpkoradiya10@gmail.com <br />
                    www.kpcbotad.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer Top --> */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about-widget">
                  <h1 className="text-white text-uppercase">Shree Kadva Patidar Educational Campus</h1>
                  <p>
                    Kpc Educational Trust- Kpc started school in June, 2007.
                    Projects that provide educational opportunities for able
                    young people from non-privileged backgrounds. Kpc
                    Educational Trust committed to providing quality education
                    to all its students. More than 1000 Students are studing in
                    our Trust,Kpc Educational Trust supports two schools and
                    also hostel with all facility.
                  </p>
                  <p className="margin-remove">
                    The infrastructure has been suitably planned to facilitate
                    learning and all round development of the child. A
                    Beautifully designed magnificent Hostel and school building
                    is a necessity to provide a healthy educational environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-share">
              <ul>
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC6TB9FuCVvsdEnaFintK5Gg"
                    target="_blank"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/accounts/login/"
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Footer Bottom --> */}
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright">
                <p>
                  © 2024 <strong>KPC Education Trust Botad</strong>. All Rights
                  Reserved.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="credits">
                <p>
                  Developed By{" "}
                  <a href="https://elitescriptech.com/" target="_blank">
                    Elite Scriptech
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- Footer End --> */}
      {/* <!-- start scrollUp  --> */}
      <div id="scrollUp">
        <i className="fa fa-angle-up"></i>
      </div>
    </React.Fragment>
  );
}

export default Footer;
