import React from "react";


function Download() {
    const obj = JSON.parse(localStorage.getItem("allImageData"));
    const DownloadsData = obj.filter((obj) => {
        return obj.imageCat === "Downloads";
    });
    return (
        <React.Fragment>
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">Downloads</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="rs-courses-3" class="rs-courses-3 sec-spacer">
                <div class="container">
                    <div class="row grid">
                        {DownloadsData.map((element, key) => {
                            var index=element.imageURL.indexOf("/upload/");
                            var newImageURL = element.imageURL.slice(0, index+8) + "fl_attachment/" + element.imageURL.slice(index+8);
                            return (
                                <div class="col-lg-4 col-md-6 grid-item">
                                    <div class="course-item">
                                        <div class="course-img">
                                            <img src={element.imageURL.replace("pdf","jpg")} alt={element.imageTitle} />
                                            <div class="course-toolbar">
                                                <h4 class="course-category"><a href="#">{element.imageTitle}</a></h4>
                                            </div>
                                        </div>
                                        <div class="course-footer text-center">
                                            <a href={newImageURL} className="text-uppercase" download={element.imageTitle}>Click here to Download 
                                                <i class="fa fa-download ps-2" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Download;
