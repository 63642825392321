import React, { useEffect, useState } from "react";
import { db } from '../firebase-config'; // update with your path to firestore config
import { getDocs, collection } from "firebase/firestore";
import { Document, Page, pdfjs } from 'react-pdf';

function AboutTrustee() {
    const [data, setData] = useState("")

    const getAboutTrustee = async () => {
        const dataSnapshot = await getDocs(collection(db, "aboutTrust"));
        const data = dataSnapshot.docs.map((doc) => doc.data());
        setData(data[0].data)
    }
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        getAboutTrustee()
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        window.scrollTo(0, 0)
    }, [])
    return (
        <React.Fragment>
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">About KPC</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
            <div class="rs-history sec-spacer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="sec-title mb-40">
                                <h2><span class="orange-color">About </span> Trust</h2>
                            </div>
                            <article>
                                <div dangerouslySetInnerHTML={{ __html: data }} />
                            </article>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default AboutTrustee;
