import React from "react";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function News(props) {
  const { sliderData } = props;

  return (
    <React.Fragment>
      <div id="rs-news" className="rs-news py-5">
        <div className="container">
          <div className="row rs-vertical-middle">
            <div className="col-lg-4 col-md-12">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">OUR </span> LATEST NEWS
                </h2>
                <p>Kpc School activities and events</p>
              </div>

              <p className="mobile-mb-50">
                Certain news paper highlight of our School activities and events
                of current academic year.
              </p>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="row rs-vertical-middle">
                <div className="col-md-12">
                  {sliderData.reverse().length > 0 ? (
                    <OwlCarousel
                      className="rs-carousel"
                      responsiveClass={true}
                      items={1}
                      margin={0}
                      autoplay={false}
                      autoplayTimeout={3000}
                      nav={true}
                      navSpeed={false}
                      navText={[
                        "<i class='fa fa-angle-left'></i>",
                        "<i class='fa fa-angle-right'></i>",
                      ]}
                      navElement="div"
                    >
                      {sliderData.map((element, key) => {
                        return (
                          <div class="news-normal-block" key={key}>
                            <div class="news-img">
                              <a href={element.imageURL} target="_blank">
                                <img src={element.imageURL} alt={element.imageTitle} />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <Skeleton style={{ height: "500px" }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default News;
