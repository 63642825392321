import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDqiS_81T3lkN4o5WbNooNIVu0DkK3-5zU",
    authDomain: "react-demo-c08da.firebaseapp.com",
    projectId: "react-demo-c08da",
    storageBucket: "react-demo-c08da.appspot.com",
    messagingSenderId: "621812541431",
    appId: "1:621812541431:web:0e86914c46c656f17270f2",
    measurementId: "G-EL3NCZL0TG"
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();