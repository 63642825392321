import React from "react";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import format from "date-fns/format";

function Events(props) {
  const { sliderData } = props;
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <React.Fragment>
      <div id="rs-events" className="rs-events py-5">
        <div className="container">
          <div className="row rs-vertical-middle">
            <div className="col-lg-4 col-md-12">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">OUR </span> SCHOOL Activity
                </h2>
              </div>
              <p className="mobile-mb-50">
                Various activities and events are conducted in school from time
                to time for the overall holistic development of the students.
              </p>
            </div>
            <div className="col-lg-8 col-md-12">
              {sliderData.reverse().length > 0 ? (
                <OwlCarousel
                  className="rs-carousel"
                  responsiveClass={true}
                  responsive={state.responsive}
                  items={2}
                  margin={10}
                  autoplay={false}
                  autoplayTimeout={3000}
                  nav={true}
                  navSpeed={false}
                  navText={[
                    "<i class='fa fa-angle-left'></i>",
                    "<i class='fa fa-angle-right'></i>",
                  ]}
                  navElement="div"
                >
                  {sliderData.map((element, key) => {
                    return (
                      <div class="event-item">
                        <div class="event-img">
                          <img
                            src={element.imageURL}
                            alt={element.imageTitle}
                          />
                          <a
                            class="image-link"
                            href={element.imageURL}
                            title={element.imageTitle}
                          >
                            <i class="fa fa-link"></i>
                          </a>
                        </div>
                        <div class="events-details text-center sec-color">
                          <div class="event-date">
                            <i class="fa fa-calendar"></i>
                            <span>
                              {element.date &&
                                format(new Date(element.date), "dd/MM/yyyy")}
                            </span>
                          </div>
                          <h4 class="event-title">
                            <a href="events-details.html">
                              {element.imageTitle}
                            </a>
                          </h4>
                          <div class="event-meta">
                            <div class="event-location">
                              <i class="fa fa-map-marker"></i>
                              <span>{element.location}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                <Skeleton style={{ height: "260px" }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Events;
