import React from "react";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Slider(props) {
  const { sliderData } = props;

  return (
    <React.Fragment>
      <div id="rs-slider" class="slider-overlay-2">
        {sliderData.reverse().length > 0 ? (
          <OwlCarousel
            className="rs-carousel"
            loop
            items={1}
            margin={0}
            dots={false}
            autoplay={true}
            autoplayTimeout={3000}
            nav={false}
            navElement="div"
          >
            {sliderData.map((element, key) => {
              return (
                <div class="item" key={key}>
                  <img src={element.imageURL} alt={element.imageTitle} />
                </div>
              );
            })}
          </OwlCarousel>
        ) : (
          <Skeleton style={{ height: "600px" }} />
        )}
      </div>
    </React.Fragment>
  );
}

export default Slider;
