import Home from "./pages/Home";
import AboutUs from "./pages/About";
import ContactUs from "./pages/Contact";
import Dashboard from "./Admin/views/Dashboard";
import Images from "./Admin/views/Images";
import AboutTrust from "./Admin/views/AboutTrust";
import Inquiry from "./Admin/views/Inquiry";
import ExamInquiry from "./Admin/views/ExamInquiry";
import AboutTrustee from "./pages/AboutTrustee";
import FRC from "./pages/FRC";
import OurTeam from "./pages/OurTeam";
import Download from "./pages/Download";
import Gallery from "./pages/Gallery";
import News from "./pages/News";

const routes = [
  {
    path: "/",
    name: "Home",
    component: <Home />,
    layout: "/main"
  },
  {
    path: "/aboutus",
    name: "About Us",
    component: <AboutUs />,
    layout: "/main"
  },
/*   {
    path: "/aboutTrustee",
    name: "About Trust",
    component: <AboutTrustee />,
    layout: "/main"
  }, */
  {
    path: "/gallery",
    name: "Gallery",
    component: <Gallery />,
    layout: "/main"
  },
  {
    path: "/news",
    name: "Press Notes",
    component: <News />,
    layout: "/main"
  },
  {
    path: "/ourTeam",
    name: "Our Staff",
    component: <OurTeam />,
    layout: "/main"
  },
  {
    path: "/frc",
    name: "FRC",
    component: <FRC />,
    layout: "/main"
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: <Download />,
    layout: "/main"
  },
  {
    path: "/contactus",
    name: "Contact Us",
    component: <ContactUs />,
    layout: "/main"
  },
];

const adminroutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    layout: "/admin"
  },
  {
    path: "/images",
    name: "Assets",
    component: <Images />,
    layout: "/admin"
  },
  {
    path: "/aboutTrust",
    name: "About Trust",
    component: <AboutTrust />,
    layout: "/admin"
  },
  {
    path: "/inquiry",
    name: "Inquiry",
    component: <Inquiry />,
    layout: "/admin"
  },
  {
    path: "/examInquiry",
    name: "Exam Inquiry",
    component: <ExamInquiry />,
    layout: "/admin"
  },

];

export default { routes, adminroutes };


