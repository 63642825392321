import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  doc,
  getDocs,
  deleteDoc,
  orderBy,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase-config";

const columns = [
  { key: "studentName", name: "Student Name" },
  { key: "dob", name: "Date of Birth" },
  { key: "email", name: "Email" },
  { key: "admissionClass", name: "Admission Class" },
  { key: "preSchoolName", name: "Previous School Name" },
  { key: "preSchoolPlace", name: "Previous School Place" },
  { key: "fatherName", name: "Father Name" },
  { key: "motherName", name: "Mother Name" },
  { key: "mobileNumber", name: "Mobile Number" },
  { key: "address", name: "Address" },
  { key: "query", name: "Query" },
];

function ExamInquiry() {
  const [id, setId] = useState();
  const [allExamInquiryData, setallExamInquiryData] = useState([]);

  const getExamInquiryData = async () => {
    const examinquirySnapshot = await getDocs(
      collection(db, "examInquiry"),
      orderBy("id", "desc")
    );
    const examinquiryData = examinquirySnapshot.docs.map((doc) => doc.data());
    console.log(examinquiryData);
    if (examinquiryData.length > 0) examinquiryData.sort().reverse();
    setallExamInquiryData(examinquiryData);
  };

  const deleteExamInquiryData = async (row, i) => {
    const examinquiryData = doc(db, "examInquiry", row.id);
    await deleteDoc(examinquiryData)
      .then((res) => {
        setallExamInquiryData(
          allExamInquiryData.filter(function (value, index, arr) {
            return value.id != row.id;
          })
        );
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getExamInquiryData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0">ExamInquiry</h4>
            <div class="page-title-right">
              <nav class="" aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">ExamInquiry</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            {/* <div class="row">
                <div class="col-sm-4">
                  <h5 className="form-title"><span>ExamInquiry</span></h5>
                </div>
              </div> */}
            <div class="table-responsive mt-3">
              <table class="mb-0 table table-bordered table-sm text-center align-items-center">
                <thead>
                  <tr>
                    <th key="id">Id</th>
                    {columns.map((item, index) => (
                      <>
                        <th key={index}>{item.name}</th>
                      </>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allExamInquiryData.length > 0 ? (
                    allExamInquiryData.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item.studentName}</td>
                          <td>{item.dob}</td>
                          <td>{item.email}</td>
                          <td>{item.admissionClass}</td>
                          <td>{item.preSchoolName}</td>
                          <td>{item.preSchoolPlace}</td>
                          <td>{item.fatherName}</td>
                          <td>{item.motherName}</td>
                          <td>{item.mobileNumber}</td>
                          <td>{item.address}</td>
                          <td>{item.query}</td>
                          <td key="actions">
                            <i
                              className="fa fa-trash pe-2"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => deleteExamInquiryData(item, i)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12">No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamInquiry;
