import React from "react";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Testimonial(props) {
  const { sliderData } = props;
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <React.Fragment>
      <div className="rs-counter blue-bg pt-100 pb-70 bg5">
        <div className="container relative">
          <div className="row rs-vertical-middle">
            <div className="col-lg-6 col-md-12">
              <div className="counter-content">
                <div class="sec-title mb-40">
                  <h2>
                    <span class="orange-color">OUR </span> Student Achievements
                  </h2>
                </div>
                <div className="counter-text">
                  <p>Our lead achievers in the field of sports and academic.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {sliderData.reverse().length > 0 ? (
                <OwlCarousel
                  className="rs-carousel"
                  responsiveClass={true}
                  responsive={state.responsive}
                  items={2}
                  margin={30}
                  autoplay={false}
                  autoplayTimeout={3000}
                  nav={true}
                  navSpeed={false}
                  navText={[
                    "<i class='fa fa-angle-left'></i>",
                    "<i class='fa fa-angle-right'></i>",
                  ]}
                  navElement="div"
                >
                  {sliderData.map((element, key) => {
                    return (
                      <div class="team-item">
                        <div class="team-img">
                          <img
                            src={element.imageURL}
                            alt={element.imageTitle}
                          />
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                <Skeleton style={{ height: "260px" }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Testimonial;
