import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { db } from "../firebase-config"; // update with your path to firestore config
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import format from "date-fns/format";

function GetInquiry() {
  const { handleSubmit, register } = useForm();
  let id = new Date().getTime().toString();

  const onSubmit = async (data, e) => {
    console.log(data);
    let datas = {
      id: id,
      studentName: data.studentName ? data.studentName : "N/A",
      dob: data.dob ? data.dob : "N/A",
      email: data.email ? data.email : "N/A",
      admissionClass: data.admissionClass ? data.admissionClass : "N/A",
      preSchoolName: data.preSchoolName ? data.preSchoolName : "N/A",
      preSchoolPlace: data.preSchoolPlace ? data.preSchoolPlace : "N/A",
      fatherName:data.fatherName ? data.fatherName : "N/A",
      motherName:data.motherName ? data.motherName : "N/A",
      mobileNumber:data.mobileNumber ? data.mobileNumber : "N/A",
      address: data.address ? data.address : "N/A",
      query: data.query ? data.query : "N/A",
    };
    await setDoc(doc(db, "examInquiry", id), datas)
      .then((res) => {
        e.target.reset();
        toast.success("Thank you! KPC-Admin will contact you soon");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0,0)

}, [])
  return (
    <React.Fragment>
      <ToastContainer />
      <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
        <div class="breadcrumbs-inner">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <h1 class="page-title my-2">Get Inquiry</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-section sec-spacer">
        <div className="container">
          <div className="contact-comment-section">
            <h3>Leave Inquiry</h3>
            <div id="form-querys"></div>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <div className="row">
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Name of Student <span className="text-danger">*</span></label>
                      <input
                        name="studentName"
                        id="studentName"
                        required
                        {...register("studentName")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Date of Birth <span className="text-danger">*</span></label>
                      <input
                        name="dob"
                        id="dob"
                        required
                        {...register("dob")}
                        className="form-control"
                        defaultValue={format(new Date(), "yyyy-MM-dd")}
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        name="email"
                        id="email"
                        {...register("email")}
                        className="form-control"
                        type="email"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Seeking Admission in Class <span className="text-danger">*</span></label>
                      <input
                        name="admissionClass"
                        id="admissionClass"
                        required
                        {...register("admissionClass")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Previous School Name <span className="text-danger">*</span></label>
                      <input
                        name="preSchoolName"
                        id="preSchoolName"
                        required
                        {...register("preSchoolName")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Place <span className="text-danger">*</span></label>
                      <input
                        name="preSchoolPlace"
                        id="preSchoolPlace"
                        required
                        {...register("preSchoolPlace")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Father's Name <span className="text-danger">*</span></label>
                      <input
                        name="fatherName"
                        id="fatherName"
                        required
                        {...register("fatherName")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Mother's Name <span className="text-danger">*</span></label>
                      <input
                        name="motherName"
                        id="motherName"
                        required
                        {...register("motherName")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Mobile Number <span className="text-danger">*</span></label>
                      <input
                        name="mobileNumber"
                        id="mobileNumber"
                        required
                        {...register("mobileNumber")}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 py-2">
                    <div className="form-group">
                      <label>Address <span className="text-danger">*</span></label>
                      <textarea
                        rows="5"
                        id="address"
                        required
                        {...register("address")}
                        name="address"
                        className="textarea form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 py-2">
                    <div className="form-group">
                      <label>
                        Query <strong>(if Any)</strong>
                      </label>
                      <textarea
                        rows="5"
                        id="query"
                        {...register("query")}
                        name="query"
                        className="textarea form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <input
                    className="btn-send"
                    type="submit"
                    value="Submit Now"
                  />
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GetInquiry;
