import React, { useEffect } from "react";
import Masonry from 'react-masonry-css'

function Gallery() {
    const obj = JSON.parse(localStorage.getItem("allImageData"));
    const GalleryData = obj.filter((obj) => {
        return obj.imageCat === "Gallery";
    });
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };
    return (
        <React.Fragment>
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">Gallery</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rs-gallery-4 rs-gallery sec-spacer">
                <div class="container">
                    <div class="row">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {GalleryData.reverse().map((element, key) => {
                                return (
                                    <div class="gallery-item">
                                        <img class="img-thumbnail img-responsive"
                                            src={element.imageURL}
                                            alt={element.imageTitle} />
                                        <div class="gallery-desc">
                                            <h3><a href="#">{element.imageTitle}</a></h3>
                                            {/* <a class="image-popup" href={element.imageURL} title={element.imageTitle}>
                                                <i class="fa fa-eye" aria-hidden="true"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                );
                            })}
                        </Masonry>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Gallery;
