import React from "react";
import Navbar from "./Navbar";

function Header() {
	return (
		<React.Fragment>
			{/* <!--Full width header Start--> */}
			<div class="full-width-header">
				{/* <!-- Toolbar Start --> */}
				<div class="rs-toolbar">
					<div class="container">
						<div class="row">
							<div class="col-lg-6 col-md-12">
								<div class="rs-toolbar-left">
									<div class="welcome-message">
										<i class="fa fa-map-marker" aria-hidden="true"></i>

										<span>Botad, Gujarat</span>
									</div>
									<div class="welcome-message">
										<i class="fa fa-volume-control-phone" aria-hidden="true"></i>

										<span><a href="tel:8780471515">+91 87804 71515</a></span>
									</div>
									<div class="welcome-message">
										<i class="fa fa-globe" aria-hidden="true"></i>
										<span><a href="mailto:rpkoradiya10@gmail.com">rpkoradiya10@gmail.com</a></span>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="rs-toolbar-right">
									<div class="toolbar-share-icon">
										<ul>
											<li><a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i></a></li>
											<li><a href="https://www.youtube.com/channel/UC6TB9FuCVvsdEnaFintK5Gg" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
											<li><a href="https://www.instagram.com/accounts/login/" target="_blank"><i className="fa fa-instagram"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Toolbar End --> */}

				{/* <!--Header Start--> */}
				<header id="rs-header" class="rs-header">
					<Navbar />
				</header>
				{/* <!--Header End--> */}
			</div>
			{/* <!--Full width header End--> */}
		</React.Fragment>
	);
}

export default Header;
