import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from '../../firebase-config'; // update with your path to firestore config
import { doc, setDoc, getDocs, collection, updateDoc } from "firebase/firestore";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AboutTrust() {
  let navigate = useNavigate();
  const [editorData, setEditorData] = useState("");
  const [id, setId] = useState("");

  const onHandleClick = async (e) => {
    console.log(id, editorData)
    if (id != '') {
      const editorAllData = {
        data: editorData
      }
      console.log(id, editorAllData, editorData)
      const noteRef = doc(db, "aboutTrust", id);
      await updateDoc(noteRef, editorAllData).then((res) => {
        toast.success("Data Update Successfully");
      }).catch((err) => {
        console.log(err)
        toast.error(err)
      });
    }
    else {
      let ID = new Date().getTime().toString()
      const editorAllData = {
        id: ID,
        data: editorData
      }
      await setDoc(doc(db, 'aboutTrust', ID), editorAllData).then((res) => {
        toast.success("Data Saved Successfully");
      }).catch((err) => {
        console.log(err)
        toast.error(err)
      });
    }

  }

  const getData = async () => {
    const dataSnapshot = await getDocs(collection(db, "aboutTrust"));
    const data = dataSnapshot.docs.map((doc) => doc.data());
    if (data && data.length > 0 && data[0].id != '') {
      setEditorData(data[0].data)
      setId(data[0].id)
      console.log(data[0]);
    }
  }
const editorConfiguration = { toolbarGroups : [{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
{ name: 'forms', groups: [ 'forms' ] },
'/',
{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
{ name: 'links', groups: [ 'links' ] },
{ name: 'insert', groups: [ 'insert' ] },
'/',
{ name: 'styles', groups: [ 'styles' ] },
{ name: 'colors', groups: [ 'colors' ] },
{ name: 'tools', groups: [ 'tools' ] },
{ name: 'others', groups: [ 'others' ] },
{ name: 'about', groups: [ 'about' ] }] };
  useEffect(() => {
    getData()
    let authToken = sessionStorage.getItem('Auth Token')

    if (authToken) {
      navigate('/aboutTrust')
    }

    if (!authToken) {
      navigate('/login')
    }
  }, [])
  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col lg="12" sm="12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <h4 class="mb-0">About Trust</h4>
              <div class="page-title-right">
                <nav class="" aria-label="breadcrumb">
                  <ol class="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                    <li className="breadcrumb-item active">About Trust</li>
                  </ol>
                </nav>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <div class="d-flex justify-content-end">
                  <button type="button" class="btn-rounded btn btn-success" onClick={(e) => onHandleClick(e)}>
                    Save
                  </button>
                </div>
              </Row>
              <Row className="mt-3">
                <div className="col-lg-12 col-sm-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorData(data);
                    }}
                    config={editorConfiguration}
                  />
                </div>
              </Row>

            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default AboutTrust;
