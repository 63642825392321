import React, { useState, useEffect } from "react";
import { pdfjs, Page, Document } from 'react-pdf';

function FRC() {
    const [active, setActive] = useState(true);
    const [docData, setDocData] = useState({});

    // const handleActive = (data) => {
    //     setDocData(data);
    //     console.log(active)
    //     setActive(!active)
    // }
    // const handleBack = () => {
    //     setActive(!active)
    // }
    const obj = JSON.parse(localStorage.getItem("allImageData"));
    const FrcData = obj.filter((obj) => {
        return obj.imageCat === "FRC-PDF";
    });
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    // }
    // useEffect(() => {
    //     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    //     window.scrollTo(0, 0)
    // }, [])

    return (
        <React.Fragment>
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">FRC</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="shop-page-area sec-spacer">
                <div class="container">
                    {!active ? (
                        <>
                            <Document
                                file={docData.imageURL}
                                renderMode="canvas"
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {Array.apply(null, Array(numPages))
                                    .map((x, i) => i + 1)
                                    .map(page => <Page pageNumber={page} width={300} scale={0.75} />)}
                            </Document>
                            <p className="text-center fw-bold text-danger pt-3">
                                Page {pageNumber} of {numPages}
                            </p>
                        </>
                    ) : (
                        <div class="row product-list">
                            {FrcData.map((element, key) => {
                                return (
                                    <div class="col-lg-4 col-md-4">
                                        <div class="single-details">
                                            <div class="images"> <a href="#" onClick={() => handleActive(element)}><img src={element.imageURL.replace("pdf", "jpg")} alt={element.imageTitle} className="img-responsive img-thumbnail" /></a>
                                            </div>
                                            <div class="triangle_left"></div>
                                            <h4><a href="#">{element.imageTitle}</a></h4>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div> */}
            <div id="rs-courses-3" class="rs-courses-3 sec-spacer">
                <div class="container">
                    <div class="row grid">
                        {FrcData.map((element, key) => {
                            var index = element.imageURL.indexOf("/upload/");
                            var newImageURL = element.imageURL.slice(0, index + 8) + "fl_attachment/" + element.imageURL.slice(index + 8);
                            return (
                                <div class="col-lg-4 col-md-6 grid-item">
                                    <div class="course-item">
                                        <div class="course-img">
                                            <img src={element.imageURL.replace("pdf", "jpg")} alt={element.imageTitle} />
                                            <div class="course-toolbar">
                                                <h4 class="course-category"><a href="#">{element.imageTitle}</a></h4>
                                            </div>
                                        </div>
                                        <div class="course-footer text-center">
                                            <a href={newImageURL} className="text-uppercase" download={element.imageTitle}>Click here to Download
                                                <i class="fa fa-download ps-2" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FRC;
