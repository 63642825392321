import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { db } from '../firebase-config'; // update with your path to firestore config
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {
    const { handleSubmit, register } = useForm();
    let id = new Date().getTime().toString()

    const onSubmit = async (data, e) => {
        let datas = {
            id: id,
            fname: data.fname ? data.fname : "N/A",
            lname: data.lname ? data.lname : "N/A",
            email: data.email ? data.email : "N/A",
            subject: data.subject ? data.subject : "N/A",
            message: data.message ? data.message : "N/A"
        }
        await setDoc(doc(db, 'contact', id), datas).then((res) => {
            e.target.reset();
            toast.success("Thank you! KPC-Admin will contact you soon");
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        window.scrollTo(0,0)

    }, [])
    return (
        <React.Fragment>
            <ToastContainer />
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">Get In Touch</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-page-section sec-spacer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3694.5575969011447!2d71.66124071442711!3d22.180906152824612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3958dd9f3297af2f%3A0xab66b03e61d25e3a!2sBotad%20kadva%20patel%20school!5e0!3m2!1sen!2sin!4v1646675217131!5m2!1sen!2sin"
                                width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                        </div>

                    </div>
                    <div className="row contact-address-section">
                        <div className="col-md-4 pl-0">
                            <div className="contact-info contact-address">
                                <i className="fa fa-map-marker"></i>
                                <h4>Address</h4>
                                <p>B/H Swami Vivekanand Sadan,Railway Station Road <br /> Botad, Gujarat 364710</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-info contact-phone">
                                <i className="fa fa-phone"></i>
                                <h4>Phone Number</h4>
                                <a href="tel:+918401448283">+91 84014 48283</a>
                                <a href="tel:(02849)242476">(02849) 242476</a>
                            </div>
                        </div>
                        <div className="col-md-4 pr-0">
                            <div className="contact-info contact-email">
                                <i className="fa fa-envelope"></i>
                                <h4>Email Address</h4>
                                <a href="mailto:rpkoradiya10@gmail.com"><p>rpkoradiya10@gmail.com</p></a>
                                <a href="#"><p>www.kpcbotad.com</p></a>
                            </div>
                        </div>
                    </div>

                    <div className="contact-comment-section">
                        <h3>Leave Comment</h3>
                        <div id="form-messages"></div>
                        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <fieldset>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>First Name*</label>
                                            <input name="fname" id="fname" required {...register("fname")} className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input name="lname" id="lname" {...register("lname")} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input name="email" id="email" {...register("email")} className="form-control" type="email" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Subject *</label>
                                            <input name="subject" id="subject" required {...register("subject")} className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Message *</label>
                                            <textarea cols="40" rows="10" id="message" required {...register("message")} name="message" className="textarea form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0">
                                    <input className="btn-send" type="submit" value="Submit Now" />
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactUs;
