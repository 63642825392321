import React from "react";
import format from "date-fns/format";

function OurTeam() {
    const obj = JSON.parse(localStorage.getItem("allImageData"));
    const TeachersData = obj.filter((obj) => {
        return obj.imageCat === "Teachers";
    });
    return (
        <React.Fragment>
            <div class="rs-breadcrumbs bg7 breadcrumbs-overlay p-5">
                <div class="breadcrumbs-inner">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1 class="page-title my-2">Our Team</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="rs-team-2" class="rs-team-2 team-all sec-spacer">
                <div class="container">
                    <div class="row">
                        {TeachersData.map((element, key) => {
                            return (
                                <div class="col-lg-3 col-md-6 col-xs-6 mb-4">
                                    <div class="card">
                                        <a href="#"><img src={element.imageURL} alt={element.imageTitle} className="img-responsive" /></a>
                                        <div class="card-body">
                                            <h5 class="text-uppercase mb-0">{element.imageTitle}</h5>
                                        </div>
                                        <div class="card-footer text-muted">
                                            <p class="designation mb-1">Designation : {element.designation}</p>
                                            <p class="designation mb-1">Qualification : {element.qualification}</p>
                                            <p class="designation mb-1">Experience : {element.experience}</p>
                                            <p class="designation mb-1">Dob : {format(new Date(element.date), "dd/MM/yyyy")}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default OurTeam;
