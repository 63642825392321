import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";

function AddImage(props) {
  const {
    onSubmit,
    url,
    setUrl,
    isEdit,
    active,
    rowData,
    allImageData,
    setallImageData,
    loader
  } = props;
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  let obj = {
    Staff: "370*430",
    Gallery: "570*550",
    Teachers: "370*430",
    Slider: "1620*820",
    News: "800*600",
    Activity: "370*240",
    Achievments: "370*430",
    Team:"270*270"
  };

  const uploadImage = (e) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "ypqvdane");
    data.append("cloud_name", "djbdscihp");
    data.append("public_id", new Date().getTime().toString());


    fetch("https://api.cloudinary.com/v1_1/djbdscihp/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setUrl(data.secure_url);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let abortController = new AbortController();
    if (isEdit) {
      const fields = ["id", "imageTitle", "imageURL", "imageCat", "role"];
      fields.forEach((field) => setValue(field, rowData[field]));
      setUrl(rowData["imageURL"]);
      setValue("date", format(new Date(rowData["date"]), "yyyy-MM-dd"));
    }
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-sm-9">
          <div className="col-auto mb-3">
            <div className="form-group">
              <label>
                Category <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                required
                name="imageCat"
                {...register("imageCat")}
                onChange={(e) => setState({ imageCat: e.target.value })}
              >
                <option value="">Select Category...</option>
                <option value="Slider">Slider</option>
                <option value="Activity">Activity</option>
                <option value="Staff">Managment-Team</option>
                <option value="News">News</option>
                <option value="Achievments">Achievments</option>
                <option value="Teachers">Our Staff</option>
                <option value="Gallery">Gallery</option>
                <option value="FRC-PDF">FRC-PDF</option>
                <option value="Downloads">Downloads</option>
              </select>
            </div>
          </div>
          <div className="col-auto mb-3">
            <div className="form-group">
              <label>
                Image Title/Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="imageTitle"
                required
                placeholder="Enter Image Title"
                {...register("imageTitle")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-auto mb-3">
            <div className="form-group">
              <label>
                Upload Image <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                required
                name="imageFile"
                placeholder="Upload File"
                {...register("imageFile")}
                onChange={(e) => uploadImage(e)}
                className="form-control mb-2"
              />
              <small id="imageFile" class="form-text text-warning bg-dark p-2">
                Please Select Image Dimension is {obj[state.imageCat] ? obj[state.imageCat] : "1600*820"} pixel
              </small>
            </div>
          </div>
          {state.imageCat == "Staff" && (
            <div className="col-auto mb-3">
              <div className="form-group">
                <label>
                  Role <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="role"
                  required
                  placeholder="Enter Role"
                  {...register("role")}
                  className="form-control"
                />
              </div>
            </div>
          )}
          {state.imageCat == "Teachers" && (
            <div className="col-auto mb-3">
              <div className="form-group">
                <label>
                  Designation <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="designation"
                  required
                  placeholder="Enter Designation"
                  {...register("designation")}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  Qualification <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="qualification"
                  required
                  placeholder="Enter Qualification"
                  {...register("qualification")}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  Experience <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="experience"
                  required
                  placeholder="Enter Experience"
                  {...register("experience")}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  Birth Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  required
                  placeholder="Select Birth Date"
                  {...register("date")}
                  className="form-control"
                  defaultValue={format(new Date(), "dd/MM/yyyy")}
                />
              </div>
            </div>


          )}
          {state.imageCat == "Activity" && (
            <>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label>
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="role"
                      required
                      placeholder="Select Date"
                      {...register("date")}
                      className="form-control"
                      defaultValue={format(new Date(), "yyyy-MM-dd")}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label>
                      Location <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      required
                      placeholder="Enter Location"
                      {...register("location")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="col-12 col-sm-3 d-flex align-items-center">
          {url ? (
            <img src={url.replace("pdf", "jpg")} class="img-thumbnail" />
          ) : (
            loading && (
              <div class="spinner-border spinner-border-sm ms-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            )
          )}
        </div>
        <div className="col-12">
          {loader ? (
            <button class="btn btn-primary" type="submit" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={url ? false : true}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}

export default AddImage;
