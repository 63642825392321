import React from "react";

function Courses() {
    return (
        <React.Fragment>
            <div id="rs-courses" className="rs-courses sec-color py-5">
                <div className="container">
                    <div className="row rs-vertical-middle">
                        <div className="col-lg-4 col-md-12">
                            <div className="sec-title mb-30">
                                <h2>ACADEMIC COURSES</h2>
                                <p>Core Course of Study</p>
                            </div>
                            <p className="mobile-mb-50">
                                Core Course of study refers to a series or selection of courses that all students are required to complete before they can move on to the next level in their education.
                            </p>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            {/* <OwlCarousel className='rs-carousel owl-carousel rs-navigation-2' id="home-slider"
                                items={2}
                                loop={true}
                                margin={30}
                                nav={true}
                                dots={true}
                                navSpeed={false}
                                autoplay={false}
                                navElement="div"
                                autoplayTimeout={5000}
                                smartSpeed={1200}
                                navText={["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]}
                            >
                                <div className="cource-item">
                                    <div className="cource-img">
                                        <img src="assets/images/courses/1.jpg" alt="" />
                                        <a className="image-link" href="courses-details.html" title="University Tour 2018">
                                            <i className="fa fa-link"></i>
                                        </a>
                                        <span className="course-value">$450</span>
                                    </div>
                                    <div className="course-body">
                                        <a href="#" className="course-category">Science</a>
                                        <h4 className="course-title"><a href="courses-details.html">Electrical Engineering</a></h4>
                                        <div className="review-wrap">
                                            <ul className="rating">
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star-half-empty"></li>
                                            </ul>
                                            <span className="review">25 Reviews</span>
                                        </div>
                                        <div className="course-desc">
                                            <p>
                                                Cras ultricies lacus consectetur, consectetur
                                                scelerisque arcu curabitur
                                            </p>
                                        </div>
                                    </div>
                                    <div className="course-footer">
                                        <div className="course-time">
                                            <span className="label">Course Time</span>
                                            <span className="desc">3 Year</span>
                                        </div>
                                        <div className="course-student">
                                            <span className="label">Course Student</span>
                                            <span className="desc">95</span>
                                        </div>
                                        <div className="class-duration">
                                            <span className="label">Class Duration</span>
                                            <span className="desc">8:30-4:00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cource-item">
                                    <div className="cource-img">
                                        <img src="assets/images/courses/2.jpg" alt="" />
                                        <a className="image-link" href="courses-details.html" title="University Tour 2018">
                                            <i className="fa fa-link"></i>
                                        </a>
                                        <span className="course-value">$450</span>
                                    </div>
                                    <div className="course-body">
                                        <a href="#" className="course-category">Science</a>
                                        <h4 className="course-title"><a href="courses-details.html">Computer Engineering</a></h4>
                                        <div className="review-wrap">
                                            <ul className="rating">
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star-half-empty"></li>
                                            </ul>
                                            <span className="review">39 Reviews</span>
                                        </div>
                                        <div className="course-desc">
                                            <p>
                                                Cras ultricies lacus consectetur, consectetur
                                                scelerisque arcu curabitur
                                            </p>
                                        </div>
                                    </div>
                                    <div className="course-footer">
                                        <div className="course-time">
                                            <span className="label">Course Time</span>
                                            <span className="desc">4 Years</span>
                                        </div>
                                        <div className="course-student">
                                            <span className="label">Course Student</span>
                                            <span className="desc">99</span>
                                        </div>
                                        <div className="class-duration">
                                            <span className="label">Class Duration</span>
                                            <span className="desc">8:30-4:00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cource-item">
                                    <div className="cource-img">
                                        <img src="assets/images/courses/3.jpg" alt="" />
                                        <a className="image-link" href="courses-details.html" title="University Tour 2018">
                                            <i className="fa fa-link"></i>
                                        </a>
                                        <span className="course-value">$450</span>
                                    </div>
                                    <div className="course-body">
                                        <a href="#" className="course-category">Science</a>
                                        <h4 className="course-title"><a href="courses-details.html">Civil Engineering</a></h4>
                                        <div className="review-wrap">
                                            <ul className="rating">
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star"></li>
                                                <li className="fa fa-star-half-empty"></li>
                                            </ul>
                                            <span className="review">22 Reviews</span>
                                        </div>
                                        <div className="course-desc">
                                            <p>
                                                Cras ultricies lacus consectetur, consectetur
                                                scelerisque arcu curabitur
                                            </p>
                                        </div>
                                    </div>
                                    <div className="course-footer">
                                        <div className="course-time">
                                            <span className="label">Course Time</span>
                                            <span className="desc">3.5 Years</span>
                                        </div>
                                        <div className="course-student">
                                            <span className="label">Course Student</span>
                                            <span className="desc">80</span>
                                        </div>
                                        <div className="class-duration">
                                            <span className="label">Class Duration</span>
                                            <span className="desc">8:30-4:00</span>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Courses;