import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import Service from "../components/Service";
import Courses from "../components/Courses";
import Events from "../components/Events";
import Staff from "../components/Staff";
import News from "../components/News";
import Testimonial from "../components/Testimonial";
import {
  doc,
  addDoc,
  getDocs,
  deleteDoc,
  orderBy,
  where,
  collection,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config"; // update with your path to firestore config

export default function Home() {
  const [allImageData, setallImageData] = useState([]);
  const getImageData = async () => {
    const imageSnapshot = await getDocs(
      collection(db, "webAssets"),
      orderBy("id", "desc")
    );
    const imageData = imageSnapshot.docs.map((doc) => doc.data());
    if (imageData && imageData.length > 0) {
      setallImageData(imageData);
      localStorage.setItem("allImageData", JSON.stringify(imageData))
    }
  };
  useEffect(() => {
    getImageData();
  }, []);
  return (
    <React.Fragment>
      <Slider
        sliderData={allImageData.filter(function (value) {
          return value.imageCat == "Slider";
        })}
      />

      {/* <!-- About Us Start --> */}
      <div class="rs-about pt-100 pb-100 ">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 pt-40">
              <div className="about-img img-part rs-animation-hover">
                <img src="assets/images/photos/13.jpeg" alt="img02" />
                <div className="overly-border"></div>
              </div>
            </div>
            <div class="col-lg-7 pl-60 py-3">
              <div class="sec-title mb-40">
                <h2>
                  <span class="orange-color">Welcome to </span> KPC-BOTAD
                </h2>
              </div>
              <p>
                The <strong>SHREE KADVA PATIDAR EDUCATIONAL CAMPUS </strong> aims to be an institution of excellence,
                dedicated to produce leaders and good human being of future.
                <br />
                <hr />
                Our team of teachers is highly qualified with internal core
                values, they are always encouraging students to have compassion
                for one another by teaching them to listen to and value each
                other’s opinions in and outside of the classroom. As teacher
                they play a crucial role in helping to influence student and
                shape their lives
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Service Start */}
      <Service />
      {/* Service End */}

      {/* <!-- About Us End --> */}

      {/* <!-- Courses Start --> */}
      {/* <Courses /> */}
      {/* <!-- Courses End --> */}

      {/* <!-- Counter Up Section Start--> */}
      <div className="rs-counter blue-bg pt-100 pb-70 bg3">
        <div className="container relative">
          <div className="row rs-vertical-middle">
            <div className="col-lg-6 col-md-12">
              <div className="counter-content">
                <div class="sec-title mb-40">
                  <h2>
                    <span class="orange-color">KPC </span> SCHOOL FAMILY
                  </h2>
                </div>
                <div className="counter-text">
                  <p>
                    The School Family builds connections between families and
                    schools, teachers and teachers, teachers and students, and
                    students and students to ensure the optimal development of
                    all.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="rs-counter-list">
                    <h2 className="counter-number plus">21</h2>

                    <h4 className="counter-desc">TEACHERS</h4>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rs-counter-list blue-color">
                    <h2 className="counter-number plus">5</h2>
                    <h4 className="counter-desc">COURSES</h4>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rs-counter-list orange-color">
                    <h2 className="counter-number plus">700 </h2>
                    <h4 className="counter-desc">STUDENTS</h4>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rs-counter-list red-color">
                    <h2 className="counter-number plus">35</h2>
                    <h4 className="counter-desc">HOSTEL STUDENTS</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Counter Down Section End --> */}

      {/* <!-- Events Start --> */}

      <Events
        sliderData={allImageData.filter(function (value) {
          return value.imageCat == "Activity";
        })}
      />
      {/* <!-- Events End --> */}

      {/* <!-- Staff Start --> */}
      <Staff
        sliderData={allImageData.filter(function (value) {
          return value.imageCat == "Staff";
        })}
      />
      {/* <!-- Staff End --> */}

      {/* <!-- Latest News Start --> */}
      <News
        sliderData={allImageData.filter(function (value) {
          return value.imageCat == "News";
        })}
      />
      {/* <!-- Latest News End --> */}

      {/* <!-- Testimonial Start --> */}
      <Testimonial
        sliderData={allImageData.filter(function (value) {
          return value.imageCat == "Achievments";
        })}
      />
      {/* <!-- Testimonial End --> */}
    </React.Fragment>
  );
}
