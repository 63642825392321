import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  doc,
  addDoc,
  getDocs,
  deleteDoc,
  orderBy,
  where,
  collection,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase-config"; // update with your path to firestore config
import AddImage from "../components/Forms/AddImage";

function Images() {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit } = useForm();
  const [url, setUrl] = useState("");
  const toastId = React.useRef(null);
  const userId = sessionStorage.getItem("User Id");
  const [active, setActive] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [id, setId] = useState();
  const [allImageData, setallImageData] = useState([]);

  const onSubmit = async (formData, e) => {
    formData["imageURL"] = url;
    if (!isEdit) {
      let id = new Date().getTime().toString();
      const imageProp = {
        id: id,
        imageTitle: formData["imageTitle"],
        imageCat: formData["imageCat"],
        imageURL: formData["imageURL"],
      };
      if (formData["imageCat"] == "Staff") imageProp["role"] = formData["role"];
      if (formData["imageCat"] == "Activity") {
        imageProp["date"] = formData["date"];
        imageProp["location"] = formData["location"];
      }
      if (formData["imageCat"] == "Teachers") {
        imageProp["designation"] = formData["designation"];
        imageProp["qualification"] = formData["qualification"];
        imageProp["date"] = formData["date"];

        imageProp["experience"] = formData["experience"];
      }
      setLoader(true);
      await setDoc(doc(db, "webAssets", id), imageProp)
        .then((res) => {
          setallImageData([imageProp, ...allImageData]);
          e.target.reset();
          setUrl("");
          setActive(true);
          setLoader(false);
          toast.success("Data Added Successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
        });
    } else {
      const imageProp = {
        id: id,
        imageTitle: formData["imageTitle"],
        imageCat: formData["imageCat"],
        imageURL: formData["imageURL"],
        role: formData["role"],
      };
      const imageData = doc(db, "webAssets", id);
      console.log("tsdfsd", id, rowData);
      // await updateDoc(imageData, imageProp).then((res) => {
      //   setallImageData([...allImageData, imageProp])
      //   // allImageData.splice(allImageData.indexOf(rowData), 1, imageProp)
      //   setRowData([]);
      //   setIsEdit(false);
      //   setUrl('');
      //   setActive(true)
      //   setLoader(false)
      //   toast.success("Data Update Successfully");
      // }).catch((err) => {
      //   console.log(err)
      //   toast.error(err)
      // });
    }
  };

  const handleclick = (flag) => {
    setActive(flag);
  };

  const updateImageData = (row) => {
    setActive(false);
    setIsEdit(true);
    setRowData(row);
    setId(row._id);
  };

  const getImageData = async () => {
    const imageSnapshot = await getDocs(
      collection(db, "webAssets"),
      orderBy("id", "desc")
    );
    const imageData = imageSnapshot.docs.map((doc) => doc.data());
    if (imageData.length > 0) imageData.sort().reverse();
    setallImageData(imageData);
  };

  const deleteImageData = async (row, i) => {
    const imageData = doc(db, "webAssets", row.id);
    await deleteDoc(imageData)
      .then((res) => {
        setallImageData(
          allImageData.filter(function (value, index, arr) {
            return value.id != row.id;
          })
        );
        toast.success("Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getImageData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Images</h4>
            <div class="page-title-right">
              <nav class="" aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Images</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div class="row">
                <div class="col-sm-4">
                  <h5 className="form-title">
                    {/* <span>Images</span> */}
                  </h5>
                </div>
                <div class="col-sm-8">
                  <div class="text-sm-end">
                    <button
                      type="button"
                      class="btn-rounded btn btn-success"
                      onClick={() => setActive(!active)}
                    >
                      {active ? "New Image" : "Back"}
                    </button>
                  </div>
                </div>
              </div>
              {!active ? (
                <AddImage
                  onSubmit={onSubmit}
                  url={url}
                  setallImageData={setallImageData}
                  allImageData={allImageData}
                  setUrl={setUrl}
                  isEdit={isEdit}
                  rowData={rowData}
                  active={active}
                  setLoader={setLoader}
                  loader={loader}
                />
              ) : (
                <div class="table-responsive mt-3">
                  <table class="mb-0 table table-bordered text-center align-items-center">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allImageData.length > 0 ? (
                        allImageData.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <img
                                  src={item.imageURL.replace("pdf", "jpg")}
                                  alt="Image"
                                  width={50}
                                  height={50}
                                />
                              </td>
                              <td>{item.imageTitle}</td>
                              <td>{item.imageCat}</td>
                              <td key="actions" style={{ width: "220px" }}>
                                {/* <button type="button" class="btn btn-outline-info me-3" onClick={(e) => updateImageData(item, i)}>
                                  <i className="fa fa-pencil-square pe-2"></i>
                                  Edit
                                </button> */}
                                <button
                                  type="button"
                                  class="btn btn-outline-danger"
                                  onClick={(e) => deleteImageData(item, i)}
                                >
                                  <i className="fa fa-trash pe-2"></i>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Images;
